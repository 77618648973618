<template>
    <div class="journey-part part" v-if="data">
        <div class="thumb-side" v-if="data.templateContentMedia &&
            data.templateContentMedia.overviewImage
            ">
            <span class="bg-el" v-if="data.templateContentMedia.overviewImage.url" v-bind:style="{
                'background-image':
                    'url(' +
                    data.templateContentMedia.overviewImage.url +
                    ')',
            }"></span>
        </div>
        <div class="content-side" v-if="data.templateContentFields">
            <div class="inner-content">
                <div class="inner-col" v-if="data.fields && data.fields !== ''">
                    <ul class="labels">

                        <li v-if="data.fields.nrofvideos &&
                            data.fields.nrofvideos.length > 0
                            ">
                            <div class="item-wrapper" v-for="(item) in data.fields.nrofvideos" :key="item.value">
                                <span class="label">{{ getEnumVal(item.value) }} classes</span>
                            </div>
                        </li>

                        <li v-if="data.fields.level &&
                            data.fields.level.length > 0
                            ">
                            <div class="item-wrapper" v-for="(item) in data.fields.level" :key="item.value">
                                <span class="label">{{ getEnumVal(item.value) }}</span>
                            </div>
                        </li>

                    </ul>
                </div>

                <h3 class="part-title">
                    {{ data.templateContentFields.overviewHeaderTitle }}
                </h3>
                <p class="part-subtitle"
                    v-if="data.fields && data.fields !== '' && data.fields.coachestrainers && data.fields.coachestrainers.length > 0">
                    {{ getEnumVal(data.fields.coachestrainers[0].value) }}
                </p>

                <div class="content-wrapper" v-if="data.templateContentFields.overviewShortDescription"
                    v-html="data.templateContentFields.overviewShortDescription"></div>

                <router-link class="button" :to="'/journey/' + data.slug">Explore this journey</router-link>
            </div>
        </div>
    </div>
</template>

<script>
// import { fetchCmsMedia } from "@streampac.io/chef_sp_1";

export default {
    data() {
        return {
            metaFields: [],
            mediaAsset: null,
        };
    },
    props: {
        observer: null,
        data: {
            type: Object,
            default: () => { },
        },
    },
    created() {
        //this.resolveCmsMedia();
        // setTimeout(() => {
        //     this.transformDataValues();
        // }, 200);

        //console.log(this.data);
    },
    inject: ["isAuthenticated"],
    methods: {
        // resolveCmsMedia() {
        // 	setTimeout(() => {
        // 		const imageField = this.data.templateContentFields.overviewImage;

        // 		if (this.data && imageField !== "") {
        // 			fetchCmsMedia(
        // 				this.isAuthenticated,
        // 				this.data.templateContentFields.overviewImage
        // 			).then((response) => {
        // 				this.mediaAsset = response;
        // 			});
        // 		}
        // 	}, 500);
        // },
        // checkAltText() {
        // 	if (this.mediaAsset && this.mediaAsset.title !== "") {
        // 		return this.mediaAsset.title;
        // 	}

        // 	return false;
        // },
        transformDataValues() {
            if (this.data && this.data.fields) {
                const fields = this.data.fields;

                if (fields.level && fields.level.length > 0) {
                    this.lookupDataFieldValues("level", fields.level);
                }

                console.log(this.metaFields);
            }
        },
        getEnumVal(val) {
            const enums = this.$store.state.getEnumLookup;

            if (enums && enums.length > 0) {
                const enumFilter = enums.filter((item) => item.id === val);

                if (enumFilter && enumFilter.length > 0) {
                    return enumFilter[0].enumValue;
                }
            }

            return "";
        },
        lookupDataFieldValues(type, targetFields) {
            setTimeout(() => {
                const enums = this.$store.state.getEnumLookup;

                let newArray = [];
                let newString = "";

                const fieldsType = typeof targetFields;

                if (
                    (fieldsType == "object" || fieldsType == "array") &&
                    targetFields &&
                    targetFields.length > 0
                ) {
                    targetFields.forEach((item) => {
                        const filteredValues = enums.filter(function (enumItem) {
                            if (enumItem.id === item.value) {
                                return enumItem;
                            }
                        });

                        if (
                            filteredValues[0] &&
                            filteredValues[0].enumValue !== ""
                        ) {
                            newArray.push(filteredValues[0].enumValue);
                        }
                    });

                    this.metaFields[type] = newArray;
                } else if (fieldsType == "string" && targetFields !== "") {
                    const filteredValues = enums.filter(function (enumItem) {
                        if (enumItem.id === targetFields) {
                            return enumItem;
                        }
                    });

                    if (
                        filteredValues[0] &&
                        filteredValues[0].enumValue !== ""
                    ) {
                        newString = filteredValues[0].enumValue;
                    }

                    this.metaFields[type] = newString;
                }

                // console.log(this.metaFields);
            }, 50);
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
