<template>
    <router-link class="blog-part part" :to="'/blog/' + data.slug">
        <div class="thumb-side" v-if="data.templateContentMedia &&
            data.templateContentMedia.overviewImage &&
            data.templateContentMedia.overviewImage !== ''
            ">
            <span class="bg-el" v-if="data.templateContentMedia.overviewImage.url" v-bind:style="{
                'background-image':
                    'url(' +
                    data.templateContentMedia.overviewImage.url +
                    ')',
            }"></span>
            <!-- <ul class="labels" v-if="data.fields.blogitems && data.fields.blogitems !== ''">
                <li>
                    <span class="label">{{
                        getEnumVal(data.fields.blogitems)
                    }}</span>
                </li>
            </ul> -->
        </div>

        <!-- <div class="thumb-side" v-if="mediaAsset">
			<span
				class="bg-el"
				v-if="mediaAsset.url"
				v-bind:style="{
					'background-image': 'url(' + mediaAsset.url + ')',
				}"
			></span>
			<ul class="labels" v-if="data.fields.blogtype">
				<li>
					<span class="label">{{ getEnumVal(data.fields.blogtype) }}</span>
				</li>
			</ul>
		</div> -->

        <div class="content-side">
            <div class="meta-info">

                <ul class="labels">
                    <li v-if="data.fields.blogitems &&
                        data.fields.blogitems.length > 0
                        " class="fullwidth">
                        <span class="item-wrapper" v-for="(item) in data.fields.blogitems" :key="item.value">
                            {{ getEnumVal(item.value) }}
                        </span>
                    </li>
                    <li v-if="data.fields.blogauthor &&
                        data.fields.blogauthor.length > 0
                        ">
                        By
                        <span class="item-wrapper" v-for="(item) in data.fields.blogauthor" :key="item.value">
                            {{ getEnumVal(item.value) }}
                        </span>
                        -

                    </li>
                    <li>{{ getFormatDate(data.publicationDate) }}</li>
                </ul>

            </div>
            <h3 class="part-title">
                {{ data.templateContentFields.displayTitle }}
            </h3>
        </div>
    </router-link>
</template>

<script>
//import { fetchCmsMedia } from "@streampac.io/chef_sp_1";

export default {
    data() {
        return {
            mediaAsset: null,
        };
    },
    inject: ["isAuthenticated"],
    props: {
        observer: null,
        data: {
            type: Object,
            default: () => { },
        },
    },
    created() {
        //console.log(this.data);
        //this.resolveCmsMedia();
    },
    mounted() {
        if (this.observer !== null && this.observer !== undefined) {
            this.observer.observe(this.$el);
        }
    },
    methods: {
        // resolveCmsMedia() {
        // 	setTimeout(() => {
        // 		if (this.data && this.data.templateContentFields.overviewImage !== "") {
        // 			fetchCmsMedia(
        // 				this.isAuthenticated,
        // 				this.data.templateContentFields.overviewImage
        // 			).then((response) => {
        // 				this.mediaAsset = response;
        // 			});
        // 		}
        // 	}, 100);
        // },
        // checkAltText() {
        // 	if (this.mediaAsset && this.mediaAsset.title !== "") {
        // 		return this.mediaAsset.title;
        // 	}

        // 	return false;
        // },
        getEnumVal(val) {
            const enums = this.$store.state.getEnumLookup;

            if (enums && enums.length > 0) {
                const enumFilter = enums.filter((item) => item.id === val);

                if (enumFilter && enumFilter.length > 0) {
                    return enumFilter[0].enumValue;
                }
            }

            return "";
        },
        getFormatDate(val) {
            let d = new Date(val);

            const monthIndex = d.getMonth() + 1;
            const year = d.getFullYear();
            const day = d.getDate();

            return day + "-" + monthIndex + "-" + year;
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
