<template>
    <div>
        <section class="section content-grid-collection" :data-content-type="contentType">
            <div class="container">
                <div class="main-filter-wrapper buttons" v-if="collectionFilter && collectionFilterType == 'buttons'">
                    <div class="inner-wrapper">
                        <Flickity ref="flickity" :options="flickityOptions" class="btns-wrapper"
                            v-for="filterType in catFilters" :key="filterType.name">
                            <li>
                                <button value="*" @click="
                                    onFilterClick('*', collectionFilter)
                                    " :class="{ active: '*' === this.filterVal[filterType.name], }">
                                    All
                                </button>
                            </li>
                            <li v-for="child in filterType.children" :key="child.id">
                                <button :value="child.id" :class="{
                                    active:
                                        child.id === this.filterVal[filterType.name],
                                }" @click="onFilterClick(child.id, collectionFilter)">
                                    {{ child.enumValue }}
                                </button>
                            </li>
                        </Flickity>
                    </div>
                </div>
                <div class="main-filter-wrapper dropdown" v-else-if="collectionFilter">
                    <button @click="showMobileFilterFunction()" class="show-filter-btn"
                        :class="{ 'show-mobile-filter': showMobileFilter }">
                        Filter
                    </button>
                    <div class="inner-wrapper" :class="{ 'show-mobile-filter': showMobileFilter }">
                        <!-- <button v-if="displayResetBtn" class="reset-all-filters" @click="resetAllFilters()">Reset
                            all</button> -->

                        <div class="filter-wrapper" v-for="filterType in catFilters" :key="filterType.name">
                            <select @change="onFilterChange" v-model="filterVal[filterType.name]">
                                <option selected="selected" value="*">
                                    {{ changeFilterTitle(filterType.name) }}
                                </option>
                                <option v-for="child in filterType.children" :key="child.id" :value="child.id">
                                    {{ child.enumValue }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="column-wrapper">
                    <div class="column grid-collection">
                        <transition name="slow-fade">
                            <div class="items-wrapper" v-if="itemContentArray &&
                                itemContentArray.length > 0 &&
                                contentType == 'trainersAndCoaches'
                                ">
                                <ul>
                                    <li class="coachestrainers" v-for="item in itemContentArray" :key="item.id">
                                        <TeacherOverviewItem :data="item" :observer="observer" />
                                    </li>
                                </ul>
                            </div>
                        </transition>

                        <transition name="slow-fade">
                            <div class="items-wrapper" v-if="itemContentArray &&
                                itemContentArray.length > 0 &&
                                contentType == 'blog'
                                ">
                                <ul>
                                    <li class="blog" v-for="item in itemContentArray" :key="item.id">
                                        <BlogOverviewItem :data="item" :observer="observer" />
                                    </li>
                                </ul>
                            </div>
                        </transition>

                        <transition name="slow-fade">
                            <div class="items-wrapper" v-if="itemContentArray &&
                                itemContentArray.length > 0 &&
                                contentType == 'journey'
                                ">
                                <ul>
                                    <li class="journey" v-for="item in itemContentArray" :key="item.id">
                                        <JourneyOverviewItem :data="item" :observer="observer" />
                                    </li>
                                </ul>
                            </div>
                        </transition>

                        <transition name="slow-fade">
                            <div class="message no-items-found" v-if="showNoItemsFound && !showLazyLoadSpinner">
                                No content found..
                            </div>
                        </transition>
                        <transition name="fade">
                            <div class="lazyload-spinner" v-if="showLazyLoadSpinner">
                                <ul>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// @import component
//import VideoOverviewItem from "@/components/VideoOverviewItem";
import {
    fetchNestedEnumValues,
    fetchTemplateContentList,
} from "@streampac.io/chef_sp_1";

import Flickity from "@/components/Flickity";

import TeacherOverviewItem from "@/components/TeacherOverviewItem";
import BlogOverviewItem from "@/components/BlogOverviewItem";
import JourneyOverviewItem from "@/components/JourneyOverviewItem";

export default {
    name: "VideoGridFilterCollection",
    inject: ["isAuthenticated"],
    components: {
        Flickity,
        TeacherOverviewItem,
        BlogOverviewItem,
        JourneyOverviewItem,
    },
    data() {
        return {
            showMobileFilter: false,
            showNoItemsFound: false,
            loadedAllItemFound: false,
            observer: null,
            totalItems: 0,
            totalLoadedItems: 0,
            catFilters: [],
            filterVal: [],
            showLazyLoadSpinner: false,
            data: null,
            itemContentArray: [],
            fetchDataParams: {
                filter: {
                    searchTerm: "",
                },
                paging: {
                    pageNumber: 1,
                    pageSize: 1,
                },
                orderBy: {
                    orderByField: "sortOrder",
                    //orderDesc: false,
                },
                //flattenFields: true,
            },
            flickityOptions: {
                initialIndex: 0,
                cellAlign: "left",
                contain: true,
                pageDots: false,
                draggable: true,
                groupCells: true,
                prevNextButtons: false,
                watchCSS: true,
            },
            displayResetBtn: false,
        };
    },
    props: {
        collectionFilter: {
            type: Object,
            required: true,
        },
        paging: {
            type: Object,
            required: true,
        },
        contentType: {
            type: String,
            required: true,
        },
        searchParams: {
            type: String,
            required: false,
        },
        collectionFilterType: {
            type: String,
            required: false,
        },
        onlyShowFree: {
            type: Boolean,
            required: false,
        },
    },
    created() {
        this.observer = new IntersectionObserver(this.onElementObserved, {
            root: this.$el,
            threshold: 0.5,
        });

        if (this.searchParams) {
            this.fetchDataParams.filter.searchTerm = this.searchParams;
        }

        if (this.paging) {
            this.fetchDataParams.paging.pageNumber = this.paging.pageNumber;
            this.fetchDataParams.paging.pageSize = this.paging.pageSize;
        }

        if (this.collectionFilter) {
            this.collectionFilter.forEach((filterType) => {
                this.filterVal[filterType] = "*";
            });

            fetchNestedEnumValues(this.isAuthenticated).then((response) => {
                this.collectionFilter.forEach((filterType) => {
                    response.forEach((name) => {
                        if (name.enumName === filterType) {
                            let enumObj = {
                                name: name.enumName,
                                children: name.enumerationValues,
                            };

                            this.catFilters.push(enumObj);
                        }
                    });
                });
            });
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (queryString && queryString !== "") {
            for (const [key, value] of urlParams.entries()) {
                if (value && value !== "") {
                    this.filterVal[key] = value;
                }
            }
        }

        setTimeout(() => {
            //this.fetchTemplateContent(1);
            this.changeFetchParams(this.filterVal);
        }, 100);

        if (this.onlyShowFree && this.onlyShowFree === true) {
            this.fetchDataParams.filter.free = true;
        }
    },
    mounted() {
        setTimeout(() => {
            this.$refs.flickity[0].on("dragStart", () => {
                this.$refs.flickity[0].$flickity.element.style.pointerEvents = "none";
            });
            this.$refs.flickity[0].on("dragEnd", () => {
                this.$refs.flickity[0].$flickity.element.style.pointerEvents = "auto";
            });
        }, 300);
    },
    unmounted() {
        this.observer.unobserve(this.$el);
    },
    methods: {
        onElementObserved(entries) {
            entries.forEach(({ target, isIntersecting }) => {
                if (isIntersecting) {
                    this.observer.unobserve(target);
                    this.totalItems = this.totalItems + 1;
                    this.totalLoadedItems = this.totalLoadedItems + 1;
                }
            });

            if (this.totalItems == this.fetchDataParams.paging.pageSize) {
                setTimeout(() => {
                    this.fetchDataParams.paging.pageNumber =
                        this.fetchDataParams.paging.pageNumber + 1;

                    this.fetchTemplateContent(
                        this.fetchDataParams.paging.pageNumber
                    );
                }, 50);

                //reset count to 0 again
                this.totalItems = 0;
            }
        },
        changeFilterTitle(val) {
            if (val == "Nr of Video's") {
                return "Classes";
            }

            return val;
        },
        onFilterChange() {
            this.changeFetchParams(this.filterVal);
            this.totalItems = 0;
            this.totalLoadedItems = 0;

            this.checkDisplayResetFilterBtn();
        },
        onFilterClick(val, collectionFilter) {
            this.filterVal[collectionFilter] = val;
            this.onFilterChange();
        },
        fetchTemplateContent(val) {
            this.showLazyLoadSpinner = true;
            this.fetchDataParams.paging.pageNumber = val;

            if (val == undefined) this.fetchDataParams.paging.pageNumber = 1;

            let inputData = this.fetchDataParams;

            if (this.contentType && this.contentType !== "") {
                const getContentTypes = this.$store.state.getContentTypes;

                if (getContentTypes) {
                    const chosenType = getContentTypes[this.contentType];

                    fetchTemplateContentList(
                        this.isAuthenticated,
                        chosenType,
                        inputData,
                        "?flattenFields=true",
                        true
                    ).then((response) => {
                        const paginationHeaders = JSON.parse(
                            response.headers["x-pagination"]
                        );

                        this.totalCount = paginationHeaders.TotalCount;

                        if (paginationHeaders.TotalCount == 0) {
                            this.showNoItemsFound = true;
                        }

                        if (
                            paginationHeaders.TotalCount ==
                            this.totalLoadedItems
                        ) {
                            this.loadedAllItemFound = true;
                        }

                        //setTimeout(() => {
                        this.data = response.data;

                        this.processFetch();
                        //}, 100);

                        setTimeout(() => {
                            this.showLazyLoadSpinner = false;
                        }, 500);
                    });

                    this.changeUrlParameters();
                }
            }
        },
        processFetch() {
            let itemContentArray = this.itemContentArray;

            if (this.data) {
                this.data.forEach((item) => {
                    itemContentArray.push(item);
                });
            }
        },
        changeFetchParams(val) {
            //reset data to prepare for filtering
            this.data = null;
            this.itemContentArray = [];
            this.fetchDataParams.paging.pageNumber = 1;
            this.loadedAllItemFound = false;
            this.totalLoadedItems = 0;
            this.showLazyLoadSpinner = false;
            this.showNoItemsFound = false;

            const valValuesArray = Object.values(val);

            var filteredArray = valValuesArray.filter(function (e) {
                return e !== "*";
            });

            const stringifyVal = filteredArray.toString().replaceAll(",", "; ");

            //Correctly set searchTerm here
            this.fetchDataParams.filter.searchTerm = stringifyVal;

            //console.log(this.fetchDataParams);

            //this.changeUrlParameters();

            //Fetch the content
            this.fetchTemplateContent(1);
        },
        changeUrlParameters() {
            if (this.searchParams && this.searchParams.length > 0) {
                let filter = new URLSearchParams();

                const pluck = (array, key) => {
                    return array.map((o) => o[key]);
                };

                const searchParamTypes = pluck(this.searchParams, "type");

                for (const [key, value] of Object.entries(this.filterVal)) {
                    if (
                        // value &&
                        // value !== "" &&
                        // value !== "*" &&
                        !searchParamTypes.includes(key)
                    ) {
                        filter.set(key, value);
                    }
                }

                history.pushState(filter.toString(), "", "?" + filter);
            } else {
                let filter = new URLSearchParams();

                for (const [key, value] of Object.entries(this.filterVal)) {
                    filter.set(key, value);
                }

                history.pushState(filter.toString(), "", "?" + filter);
            }
        },
        showMobileFilterFunction() {
            this.showMobileFilter = !this.showMobileFilter;
        },
        resetAllFilters() {
            if (this.filterVal && this.filterVal !== '') {
                Object.keys(this.filterVal).forEach(key => {

                    if (this.collectionFilter && this.collectionFilter !== '' && this.collectionFilter.includes(key)) {
                        this.filterVal[key] = '*';
                    }

                });

                this.onFilterChange();
            }
        },
        checkDisplayResetFilterBtn() {
            let valsArray = [];

            //Go through each filter val
            Object.keys(this.filterVal).forEach(key => {
                if (this.collectionFilter && this.collectionFilter !== '' && this.collectionFilter.includes(key)) {
                    if (this.filterVal[key] !== '*') {
                        //if val equals a real value
                        valsArray.push(this.filterVal[key]);
                    }
                }
            });

            if (valsArray && valsArray.length > 0) {
                this.displayResetBtn = true;
            } else {
                this.displayResetBtn = false;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<style lang="scss">
// .video-grid-collection {
// 	.column.grid-collection {
// 		display: flex;
// 		flex-flow: row wrap;
// 		gap: 50px;
// 		.video-item {
// 			flex: 0 1 calc(33.33% - 33.33px);
// 		}
// 	}
// }
.blogsoverview .main-filter-wrapper,
.recipesoverview .main-filter-wrapper {
    margin-top: 0;
}

@media only screen and (max-width: 1220px) {
    .btns-wrapper {
        .flickity-viewport {
            overflow: visible !important;

            .flickity-cell {
                width: auto;
                margin-right: 15px;
            }
        }
    }
}
</style>
